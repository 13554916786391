
import LazyHydrate from 'vue-lazy-hydration'
import analytics from '~/mixins.js/analytics'
import { getTextFromEvent } from '~/utils/helpers'

export default {
  components: {
    LazyHydrate,
    'id-header-logo': () => import('~/components/header/Logo.vue'),
    'id-header-cart': () => import('~/components/header/Cart.vue'),
    'id-header-drop-down-menu': () => import('~/components/header/DropDownMenu.vue'),
    'id-header-login': () => import('~/components/header/Login.vue'),
    'id-header-search': () => import('~/components/header/Search.vue'),
    'id-header-favourites': () => import('~/components/header/Favourites.vue')
  },
  mixins: [analytics],
  data () {
    return {
      isSticky: false,
      isSideMenuActive: false
    }
  },
  created () {
    if (process.browser) {
      window.addEventListener('scroll', this.onScroll)
    }
  },
  destroyed () {
    if (process.browser) {
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  methods: {
    onScroll () {
      if (process.browser) {
        this.isSticky = window && window.pageYOffset > 200
      }
    },
    toggleSideMenu () {
      document.querySelectorAll('body')[0].classList.toggle('menu-active')
    },
    trackClick (event) {
      const text = getTextFromEvent(event)
      this.trackEvent('Menu', text)
    }
  }
}
